/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Slider from "react-slick"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  PrevArrow,
  NextArrow,
  VideoPlayer,
} from "../components/elements"

const GenericPage = ({ data, pageContext }) => {
  const page = data.datoCmsGenericPage
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />

      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={data.datoCmsAsset.fluid}>
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            >
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Container>
            <Overlay sx={{ opacity: 1 }} />
          </BackgroundImage>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </Hero>

        {/* Content */}
        <Section id="content">
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                width: ["full"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: ["full", "4/5", "2/3"] }}>
                {page.video && (
                  <Box sx={{ mt: -3, mb: 4 }}>
                    <VideoPlayer url={page.video.url} />
                  </Box>
                )}

                <Text
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />

                {page.images.length > 0 ? (
                  <Section id="gallery">
                    <Flex
                      sx={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box sx={{ width: ["full"] }}>
                        <Slider {...settings} sx={{ color: "white" }}>
                          {page.images.map(img => (
                            <img src={img.url} alt=" " key={img.url} />
                          ))}
                        </Slider>
                      </Box>
                    </Flex>
                  </Section>
                ) : null}
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default GenericPage

export const query = graphql`
  query genericPageQuery($slug: String!) {
    datoCmsGenericPage(
      slug: { eq: $slug }
      meta: { status: { eq: "published" } }
    ) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      meta {
        status
      }
      images {
        url
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      video {
        url
      }
      slug
      title
      seoMetaTags {
        tags
      }
    }
    datoCmsAsset(id: { eq: "DatoCmsAsset-1780184" }) {
      id
      fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`
